import gql from "graphql-tag"
import {
  BillingFieldsFragmentDoc,
  BillingPlanFieldsFragmentDoc,
  CreditCardFieldsFragmentDoc,
  PermissionsForOrganizationFragmentDoc,
} from "@modules/organization/fragments.generated"

export const AVAILABLE_PLANS = gql`
  query availablePlans(
    $organizationId: UUID
    $interval: BillingInterval!
    $group: String
  ) {
    availableSubscriptionPlans(
      organizationId: $organizationId
      interval: $interval
      group: $group
    ) {
      ...billingPlanFields
      isRecommended
      nextBillingDate
      formattedMonthlyAmount
      quantity
    }
  }
  ${BillingPlanFieldsFragmentDoc}
`

export const ORGANIZATION_DETAILS = gql`
  query organizationDetails($id: UUID!) {
    organizationDetails(id: $id) {
      id
      name
      billing {
        ...billingFields
        creditCard {
          ...creditCardFields
        }
        plan {
          ...billingPlanFields
        }
      }
      permissions {
        ...permissionsForOrganization
      }
      rteSummary {
        id
        RTEsInCurrentDay
        RTEsInCurrentPeriod
        limit
      }
    }
  }
  ${BillingFieldsFragmentDoc}
  ${BillingPlanFieldsFragmentDoc}
  ${CreditCardFieldsFragmentDoc}
  ${PermissionsForOrganizationFragmentDoc}
`

export const REQUEST_PLAN_CHANGE = gql`
  mutation requestPlanChange($request: RequestPlanChangeInfoInput!) {
    requestPlanChange(request: $request) {
      success
      message
    }
  }
`
