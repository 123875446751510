import * as React from "react"
import {
  StyledModal,
  StyledModalHeader,
  StyledModalBody,
  StyledModalActions,
  Button,
  ModalCard,
  Modal,
  Text,
} from "gatsby-interface"
import { PlanInfo } from "../shared/constants/types"
import { BillingInterval, MachinePricingTier } from "@modules/graphql/types"

export type PlanChangeRequestConfirmationProps = {
  nextPlan: PlanInfo
  isOpen: boolean
  onConfirm: () => void
  onDismiss: () => void
}

export function PlanChangeRequestConfirmation({
  isOpen,
  onDismiss,
  onConfirm,
  nextPlan,
}: PlanChangeRequestConfirmationProps) {
  const isCancellation = nextPlan.tier === MachinePricingTier.Free
  const variant = isCancellation ? "WARNING" : "ACTION"

  return (
    <React.Fragment>
      <Modal aria-label="Plan change details to confirm" isOpen={isOpen}>
        <ModalCard>
          <StyledModal variant={variant}>
            <StyledModalHeader onCloseButtonClick={onDismiss}>
              Please confirm your plan change
            </StyledModalHeader>
            <StyledModalBody>
              <Text>
                Are you sure you want to change to the following plan?
              </Text>
              <Text>
                Plan: <strong>{nextPlan.planName}</strong>
              </Text>
              {!isCancellation ? (
                <Text>
                  Billing Interval:{" "}
                  <strong>
                    {nextPlan.billingInterval === BillingInterval.Monthly
                      ? `Monthly`
                      : `Yearly`}
                  </strong>
                </Text>
              ) : (
                <Text noMarginBottom={true}>
                  This will take effect at the end of your billing period.
                </Text>
              )}
              <StyledModalActions>
                <Button onClick={onDismiss}>Cancel</Button>
                <Button onClick={onConfirm}>Confirm</Button>
              </StyledModalActions>
            </StyledModalBody>
          </StyledModal>
        </ModalCard>
      </Modal>
    </React.Fragment>
  )
}
