import React from "react"
import { PropTypes } from "prop-types"
import { Link } from "gatsby"
import styled from "@emotion/styled"

import { billing as billingText } from "@modules/locales/default.js"
import { Text } from "gatsby-interface"

const FooterRoot = styled(`div`)`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`

const FooterMessage = styled(Text)`
  margin-top: ${p => p.theme.space[3]};
  color: ${p => p.theme.colors.purple[60]};
  font-size: ${p => p.theme.fontSizes[1]};
  text-align: right;
  width: 100%;

  a {
    font-size: inherit;
  }

  ${p => p.theme.mediaQueries.tablet} {
    width: 45%;
  }
`

export function Footer({ text }) {
  return (
    <FooterRoot>
      <FooterMessage>
        {text}
        <Link to="/terms-and-conditions">{billingText.termsAndConditions}</Link>
      </FooterMessage>
    </FooterRoot>
  )
}

Footer.propTypes = {
  text: PropTypes.string,
}

export default Footer
