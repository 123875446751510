import * as React from "react"
import * as Yup from "yup"
import { navigate } from "gatsby"
import {
  Button,
  InputConnectedField,
  ThemeCss,
  TextAreaConnectedField,
  RadioButtonConnectedField,
} from "gatsby-interface"
import { downgradeReasons } from "./PlanChangeRequest.data"
import { MdArrowForward } from "react-icons/md"
import Form from "@modules/form/components/Form"
import { changePlanRequest as text } from "@modules/locales/default.js"
import { getPathToOrgSettings } from "@modules/organization/shared/utils"
import { planChanges, PlanChangeIdentifier } from "./PlanChangeRequest.data"
import { FormikHelpers } from "formik"

export type FormValues = {
  firstName: string
  lastName: string
  email: string
  downgradeReason?: string
  reasonDetail?: string
}

const getValidationSchema = ({
  detailsRequired,
  downgradeReasonRequired,
}: {
  detailsRequired?: boolean
  downgradeReasonRequired?: boolean
}) =>
  Yup.object().shape<FormValues>({
    firstName: Yup.string().required(`The field is required`),
    lastName: Yup.string().required(`The field is required`),
    email: Yup.string()
      .email(`Invalid email`)
      .required(`Required`),
    downgradeReason: downgradeReasonRequired
      ? Yup.string()
          .oneOf(downgradeReasons)
          .required(`The field is required`)
      : undefined,
    reasonDetail: detailsRequired
      ? Yup.string().required(`The field is required`)
      : undefined,
  })

export type PlanChangeRequestFormProps = {
  formValues: FormValues
  organizationId: string
  errorAlert: JSX.Element | null
  onSubmit: (val: FormValues, action: FormikHelpers<FormValues>) => void
  changeIdentifier?: PlanChangeIdentifier
}

export function PlanChangeRequestForm({
  formValues,
  organizationId,
  errorAlert,
  onSubmit,
  changeIdentifier,
}: PlanChangeRequestFormProps) {
  if (!changeIdentifier) {
    return null
  }

  const detailsRequired =
    changeIdentifier !== PlanChangeIdentifier.UpdateProfessional

  const downgradeReason = planChanges?.[changeIdentifier]?.downgradeReason

  const detailsFieldLabel = downgradeReason
    ? `Please provide more detail`
    : `Reason for changing plan`

  const validationSchema = getValidationSchema({
    detailsRequired,
    downgradeReasonRequired: Boolean(downgradeReason),
  })

  return (
    <Form
      data-form="request"
      initialValues={formValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ isSubmitting }) => (
        <Form.FormElement css={formCss} applySpacing noValidate>
          <InputConnectedField label="First name" name="firstName" required />
          <InputConnectedField label="Last name" name="lastName" required />
          <InputConnectedField label="Email" name="email" required />

          {downgradeReason && (
            <RadioButtonConnectedField
              label="Reason for changing plan"
              name="downgradeReason"
              required
              options={downgradeReason.map(reason => ({
                label: reason,
                value: reason,
              }))}
            />
          )}

          {detailsRequired && (
            <TextAreaConnectedField
              label={detailsFieldLabel}
              name="reasonDetail"
              rows={4}
              required
            />
          )}

          {errorAlert}

          <div css={actionsCss}>
            <Button
              variant="SECONDARY"
              tone="NEUTRAL"
              onClick={() => navigate(getPathToOrgSettings(organizationId))}
            >
              {text.cancel}
            </Button>
            <Button
              type="submit"
              loading={isSubmitting}
              rightIcon={<MdArrowForward />}
              loadingLabel="Confirming"
            >
              {text.submit}
            </Button>
          </div>
        </Form.FormElement>
      )}
    </Form>
  )
}

/* styles */

const formCss: ThemeCss = theme => ({
  margin: `${theme.space[9]} 0`,

  label: {
    fontSize: theme.fontSizes[2],
  },
})

const actionsCss: ThemeCss = theme => ({
  marginTop: theme.space[6],
  display: `flex`,
  justifyContent: `space-between`,
})
