import Subscribe from "@modules/billing/components/Subscribe"
import { useFlags } from "@modules/featureFlags"
import { MachinePricingTier } from "@modules/graphql/types"
import { PageContent } from "@modules/layout/components/Page"
import { billing as text } from "@modules/locales/default.js"
import { ErrorModal } from "@modules/modal"
import { getPathToOrgSettings } from "@modules/organization/shared/utils"
import Loading from "@modules/ui/components/Loading"
import { LinkButton } from "gatsby-interface"
import { PropTypes } from "prop-types"
import * as React from "react"
import { MdArrowBack } from "react-icons/md"
import { PlanInformation } from "../shared/components/PlanInformation"
import { isFreePlan, isTrialingPlan } from "../shared/utils"
import { useLocation } from "@gatsbyjs/reach-router"
import { isBillingChange, getInitialPlanInfo } from "./Billing.helpers"
import { PlanSelector } from "@modules/billing/shared/components/PlanSelector"
import { useAvailableBillingPlans } from "@modules/billing/shared/hooks/useAvailableBillingPlans"
import { PlanChangeRequest } from "./PlanChangeRequest"

export function BillingForm({
  billing,
  organizationId,
  organizationName,
  user,
}) {
  const [planInfo, setPlanInfo] = React.useState()
  const { flags, ready } = useFlags()
  const { search } = useLocation()

  React.useEffect(() => {
    const initialValues = getInitialPlanInfo(billing?.plan, search)

    if (initialValues) {
      setPlanInfo({
        planId: ``,
        planName: null,
        billingInterval: initialValues.billingInterval,
        buildsTier: initialValues.buildsTier,
        hostingTier: initialValues.hostingTier,
        tier: initialValues.tier,
      })
    }
  }, [billing])

  const isTrialing = isTrialingPlan(billing.status)
  const isFree = isFreePlan(billing.status)

  const allPlans = useAvailableBillingPlans({
    organizationId,
  })

  if (allPlans.error) {
    return <ErrorModal errMsg={allPlans.error.message} />
  }

  if (allPlans.loading || !planInfo) {
    return <Loading delay={1000} message="loading plan & payment details..." />
  }

  const diyOptions = [
    MachinePricingTier.Professional,
    MachinePricingTier.Standard,
    MachinePricingTier.Performance,
  ]

  const plan = allPlans?.plans?.find(plan => plan.id === planInfo?.planId)

  const billingFormVisible = Boolean(
    (isTrialing || isFree) && diyOptions.includes(planInfo?.tier) && plan
  )

  const requestFormVisible = Boolean(
    !billingFormVisible && isBillingChange(planInfo, billing)
  )

  if (!ready) {
    return null
  }

  const generalCancelVisible = !requestFormVisible

  return (
    <PageContent.Positioner>
      <div
        css={{
          maxWidth: `80rem`,
          margin: `0 auto`,
        }}
      >
        <PlanInformation
          organizationId={organizationId}
          permissionToCreate={false}
          onBillingPage={true}
          css={{ margin: `0 auto 3rem` }}
        />

        <PlanSelector
          selectedPlan={planInfo}
          onChange={setPlanInfo}
          billingPlans={allPlans.plans}
          currentPlan={billing.plan}
          currentPlanOnTrial={isTrialing}
          professionalIsUnavailable={true}
        />

        {requestFormVisible && (
          <PlanChangeRequest
            user={user}
            organizationId={organizationId}
            currentPlan={billing.plan}
            currentPlanOnTrial={isTrialing}
            nextPlan={planInfo}
          />
        )}

        {generalCancelVisible && (
          <LinkButton
            to={getPathToOrgSettings(organizationId)}
            variant="SECONDARY"
            leftIcon={<MdArrowBack />}
            css={theme => ({
              marginTop: theme.space[7],
            })}
          >
            {text.cancel}
          </LinkButton>
        )}
      </div>
    </PageContent.Positioner>
  )
}

BillingForm.propTypes = {
  organizationId: PropTypes.string,
}
