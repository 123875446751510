import React, { Fragment } from "react"
import { PropTypes } from "prop-types"
import styled from "@emotion/styled"
import { navigate } from "gatsby"
import { useMutation } from "react-apollo"
import { Heading } from "gatsby-interface"
import { billing as text } from "@modules/locales/default.js"
import { CheckoutForm, PaymentProvider } from "@modules/payment"
import { SUBSCRIBE_TO_PLAN } from "@modules/payment/queries"
import Review from "./Review"
import Footer from "./Footer"
import usePlanChangeState from "../shared/hooks/usePlanChangeState"
import { getPathToOrgSettings } from "@modules/organization/shared/utils"
import { useTriggerErrorAlert } from "@modules/ui/components/ErrorAlert"
import { useLocation } from "@gatsbyjs/reach-router"
import * as qs from "query-string"

const headerCss = theme => ({
  padding: `${theme.space[9]} 0 ${theme.space[7]}`,
})

const FormRoot = styled(`div`)`
  margin: 0 auto;
  max-width: 100%;
`

function CheckoutFormWithMutation(props) {
  const [mutate] = useMutation(SUBSCRIBE_TO_PLAN)
  return <CheckoutForm mutate={mutate} {...props} />
}

function Subscribe({ organizationId, plan }) {
  const { clearPlanChangeRequest, storePlanChangeRequest } = usePlanChangeState(
    organizationId
  )
  const pathToOrgSettings = getPathToOrgSettings(organizationId)
  const [setError, errorAlert] = useTriggerErrorAlert()
  const { search } = useLocation()
  const { siteTransfer } = qs.parse(search)

  const successPath = siteTransfer
    ? `/dashboard/site-transfer/${siteTransfer}`
    : pathToOrgSettings

  return (
    <Fragment>
      <Heading css={headerCss} id="add-payment-method">
        {text.addPayment}
      </Heading>
      <PaymentProvider>
        <FormRoot>
          <CheckoutFormWithMutation
            cancelCallback={() => navigate(pathToOrgSettings)}
            submitButtonText={text.startSubscription}
            cancelButtonText={text.cancel}
            planId={plan.id}
            organizationId={organizationId}
            showError={message => {
              setError({ message })
            }}
            showSuccess={() => {
              // Clear previous plan change info
              clearPlanChangeRequest()
              storePlanChangeRequest(plan.name)
              navigate(successPath)
            }}
          >
            <Review plan={plan} />
            {errorAlert}
          </CheckoutFormWithMutation>
        </FormRoot>
      </PaymentProvider>
      <Footer text={text.subscriptionNote} />
    </Fragment>
  )
}

Subscribe.propTypes = {
  organizationId: PropTypes.string,
  plan: PropTypes.object,
  name: PropTypes.string,
}

export default Subscribe
