import {
  MachinePricingTier,
  SubscriptionPlan,
  BillingInterval,
} from "@modules/graphql/types"
import { PlanChange, PlanChangeIdentifier } from "./PlanChangeRequest.data"
import {
  useChangePlanMutation,
  useCancelPlanMutation,
  useRequestPlanChangeMutation,
} from "@modules/billing/queries.generated"
import useTracker from "@modules/analytics/hooks/useTracker"
import { SegmentEventType } from "@modules/analytics"
import { FormValues } from "./PlanChangeRequestForm"
import { PlanInfo } from "../shared/constants/types"
import usePlanChangeState from "@modules/billing/shared/hooks/usePlanChangeState"
import { getPathToOrgSettings } from "@modules/organization/shared/utils"
import { navigate } from "gatsby"

export type IdentifyRequestedPlanChangeProps = {
  fromPlan?: MachinePricingTier | null
  isTrial?: boolean
  toPlan?: MachinePricingTier | null
  fromInterval?: BillingInterval | null
  toInterval?: BillingInterval | null
}

export function identifyRequestedPlanChange({
  fromPlan,
  isTrial,
  toPlan,
  fromInterval,
  toInterval,
}: IdentifyRequestedPlanChangeProps) {
  let planChange: PlanChange = {
    to: toPlan,
    from: fromPlan,
    isTrial,
    fromInterval,
    toInterval,
  }

  // the user is going from a non-enterprise plan to an enterprise plan
  if (
    toPlan === MachinePricingTier.Enterprise &&
    fromPlan !== MachinePricingTier.Enterprise
  ) {
    planChange.identifier = PlanChangeIdentifier.UpgradeToEnterprise
    planChange.action = `upgrade`

    return planChange
  }

  // the user wants to downgrade from Agency or Enterprise(a plan that is not self-serve) to another plan
  if (
    (fromPlan === MachinePricingTier.Agency &&
      toPlan !== MachinePricingTier.Agency) ||
    (fromPlan === MachinePricingTier.Enterprise &&
      toPlan !== MachinePricingTier.Enterprise)
  ) {
    planChange.identifier = PlanChangeIdentifier.DowngradeFromAgencyOrEnterprise
    planChange.action = `downgrade`

    return planChange
  }

  // user wants to change intervals in the agency plan
  if (
    fromPlan === MachinePricingTier.Agency &&
    toPlan === MachinePricingTier.Agency
  ) {
    planChange.identifier = PlanChangeIdentifier.UpdateAgency
    planChange.action = `update`

    return planChange
  }

  // user wants to choose agency plan coming from free or professional
  if (
    toPlan === MachinePricingTier.Agency &&
    fromPlan !== MachinePricingTier.Agency
  ) {
    planChange.identifier = PlanChangeIdentifier.UpgradeToAgency
    planChange.action = `upgrade`

    return planChange
  }

  // user wants to change intervals in the professional plan, toPlan value is enough to identify the case, the upgrade to professional action is done by billing form (Subscribe) not the planChangeRequest
  if (toPlan === MachinePricingTier.Professional) {
    planChange.identifier = PlanChangeIdentifier.UpdateProfessional
    planChange.action = `update`

    return planChange
  }

  // user is trialing a plan, and wants to downgrade to free plan
  if (toPlan === MachinePricingTier.Free && isTrial) {
    planChange.identifier = PlanChangeIdentifier.DowngradeToFreeOnTrial
    planChange.action = `downgrade`

    return planChange
  }

  if (toPlan === MachinePricingTier.Free) {
    planChange.identifier = PlanChangeIdentifier.DowngradeToFree
    planChange.action = `downgrade`

    return planChange
  }

  return null
}

export type UseHandleSubmitConfirmationProps = {
  organizationId: string
  currentPlan: SubscriptionPlan
  setError: React.Dispatch<
    React.SetStateAction<{
      message: string
    } | null>
  >
}

export function useHandleSubmitConfirmation({
  organizationId,
  currentPlan,
  setError,
}: UseHandleSubmitConfirmationProps) {
  const { storePlanChangeRequest } = usePlanChangeState(organizationId)
  const { trackSegment } = useTracker()
  const [changePlan] = useChangePlanMutation()
  const [cancelPlan] = useCancelPlanMutation()
  const [sendRequest] = useRequestPlanChangeMutation()

  type HandlerProps = {
    nextPlan: PlanInfo
    formValues: FormValues
    requestedPlanChange?: PlanChange | null
  }

  const handler = async ({
    nextPlan,
    formValues,
    requestedPlanChange,
  }: HandlerProps) => {
    try {
      if (
        requestedPlanChange?.identifier === PlanChangeIdentifier.DowngradeToFree
      ) {
        await cancelPlan({ variables: { organizationId } })

        trackSegment({
          type: SegmentEventType.Track,
          event: `Cancel Plan Form Submitted`,
          properties: {
            organizationId,
            plan: currentPlan.name,
            newPlan: `Free`,
            downgradeReason: formValues?.downgradeReason || ``,
            moreDetails: formValues?.reasonDetail || ``,
          },
        })
      }

      if (
        requestedPlanChange?.identifier ===
          PlanChangeIdentifier.UpdateProfessional &&
        nextPlan.planId
      ) {
        await changePlan({
          variables: {
            organizationId,
            nextPlanId: nextPlan.planId,
          },
        })

        trackSegment({
          type: SegmentEventType.Track,
          event: `Changed Plan Form Submitted`,
          properties: {
            organizationId,
            plan: currentPlan.name,
            newPlan: `Professional`,
            downgradeReason: formValues?.downgradeReason || ``, // applicable when the ...
            moreDetails: formValues?.reasonDetail || ``, // change is a downgrade
          },
        })
      }

      const { reasonDetail, ...restOfFormValues } = formValues

      await sendRequest({
        variables: {
          request: {
            ...restOfFormValues,
            reason: reasonDetail || ``,
            // @ts-ignore, practically deprecated value, however RequestPlanChangeInfoInput type define it as required
            plan: currentPlan?.buildsTier,
            currentPlan: currentPlan.name,
            buildsTier: currentPlan.buildsTier,
            hostingTier: currentPlan.hostingTier,
            billingInterval: currentPlan.interval,
            nextTier: nextPlan.tier,
            nextBuildsTier: nextPlan.buildsTier,
            nextHostingTier: nextPlan.hostingTier,
            nextBillingInterval: nextPlan.billingInterval,
            organizationId,
          },
        },
      })

      storePlanChangeRequest(nextPlan.planName)
      navigate(getPathToOrgSettings(organizationId))
    } catch (err) {
      setError(err as any)
    }
  }

  return handler
}
