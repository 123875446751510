import { BillingInterval, MachinePricingTier } from "@modules/graphql/types"

export enum PlanChangeIdentifier {
  UpgradeToEnterprise = `upgradeToEnterprise`,
  DowngradeFromAgencyOrEnterprise = `downgradeFromAgencyOrEnterprise`,
  UpgradeToAgency = `upgradeToAgency`,
  UpdateAgency = `updateToAgency`,
  UpdateProfessional = `updateProfessional`,
  DowngradeToFree = `downgradeToFree`,
  DowngradeToFreeOnTrial = `downgradeToFreeOnTrial`,
}

export type ChangeDirection = `upgrade` | `downgrade` | `update`

export type PlanChange = {
  action?: ChangeDirection
  from?: MachinePricingTier | null
  to?: MachinePricingTier | null
  identifier?: PlanChangeIdentifier
  isTrial?: boolean
  fromInterval?: BillingInterval | null
  toInterval?: BillingInterval | null
}

export type ChangeDescription = {
  heading: string
  lede?: string
  note?: string
  downgradeReason?: string[]
}

export const downgradeReasons = [
  "Builds are failing",
  "Missing features",
  "Outages",
  "Difficult to use",
  "Too expensive",
  "Change of priorities / project was cancelled",
  "Something else",
]

const requestNote = `We'll get in touch with you as soon as possible. If there is a preferred time to do so, please let us know.`

export const planChanges: Record<PlanChangeIdentifier, ChangeDescription> = {
  [PlanChangeIdentifier.UpgradeToEnterprise]: {
    heading: `It's time for us to roll out the red carpet!`,
    lede: `We're here to personalize a solution to meet your exact needs. Let's chat through how we can best equip your team to succeed on our Cloud Enterprise plan.`,
    note: requestNote,
  },
  [PlanChangeIdentifier.DowngradeFromAgencyOrEnterprise]: {
    heading: `Change to`,
    note: requestNote,
    downgradeReason: downgradeReasons,
  },
  [PlanChangeIdentifier.UpgradeToAgency]: {
    heading: `Look at you grow!`,
    lede: `Congratulations on your continued success! We're here to help you in the next stage of your Gatsby journey as you scale your client relationships. Let's hop on a call to ensure you're set up for success on our Agency plan.`,
    note: requestNote,
  },
  [PlanChangeIdentifier.UpdateAgency]: {
    heading: `Change to Agency`,
    note: requestNote,
  },
  [PlanChangeIdentifier.UpdateProfessional]: {
    heading: `Change to Professional`,
    lede: `Your plan change request will be processed immediately.`,
  },
  [PlanChangeIdentifier.DowngradeToFree]: {
    heading: `Cancel Plan`,
    lede: `Cancel your billing subscription.`,
    note: `We're sorry to see you go! Your subscription will cancel at the end of your current billing period.`,
    downgradeReason: downgradeReasons,
  },
  [PlanChangeIdentifier.DowngradeToFreeOnTrial]: {
    heading: `Cancel Plan`,
    note: `It looks like you're currently on our free trial! Your account will automatically be changed to the free tier at the end of your trial.`,
  },
}
