import React, { useEffect } from "react"
import { PropTypes } from "prop-types"
import { useQuery } from "react-apollo"
import { navigate } from "gatsby"
import { PageContent, PageHeader } from "@modules/layout/components/Page"
import {
  billing as text,
  organizations as orgText,
} from "@modules/locales/default.js"
import Loading from "@modules/ui/components/Loading"
import { useTracker } from "@modules/analytics"
import { setOrgEnvVars } from "@modules/fullStory"

import { ORGANIZATION_DETAILS } from "@modules/billing/queries"
import { BillingForm } from "./BillingForm"
import { getPathToOrgSites } from "@modules/organization/shared/utils"

function Billing({ organizationId, user }) {
  const { trackPageViewed } = useTracker()

  const { data, error, loading } = useQuery(ORGANIZATION_DETAILS, {
    variables: {
      id: organizationId,
    },
  })

  useEffect(() => {
    trackPageViewed(`Add Payment Information`)
  }, [])

  const orgSitesPath = getPathToOrgSites(organizationId)

  if (error) {
    navigate(orgSitesPath)
    return null
  }

  if (loading) {
    return <Loading delay={1000} message={orgText.messages.loadingDetails} />
  }

  const {
    organizationDetails: {
      name,
      billing,
      permissions: {
        role,
        billing: { create },
      },
    },
  } = data

  if (!create) {
    navigate(orgSitesPath)
    return null
  }

  if (!organizationId) {
    return null
  }

  // Call env vars API from fullstory
  setOrgEnvVars(
    name,
    billing.plan.name,
    billing.status,
    billing.trialDaysRemaining,
    role
  )

  return (
    <PageContent css={theme => ({ marginBottom: theme.space[15] })}>
      <PageHeader variant="CENTER" css={{ paddingTop: 0 }}>
        <PageHeader.Heading
          css={theme => ({
            fontSize: theme.fontSizes[7],
            maxWidth: `none`,
            [theme.mediaQueries.desktop]: {
              fontSize: theme.fontSizes[9],
            },
          })}
        >
          {text.changePlanFor} <b>{name}</b>
        </PageHeader.Heading>
      </PageHeader>

      <BillingForm
        billing={billing}
        organizationId={organizationId}
        organizationName={name}
        user={user}
      />
    </PageContent>
  )
}

Billing.propTypes = {
  organizationId: PropTypes.string,
  interval: PropTypes.string,
}

export default Billing
