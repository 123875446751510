import { BillingInterval } from "@modules/graphql/types"
import { Heading, ThemeCss } from "gatsby-interface"
import * as React from "react"
import { MdInfo } from "react-icons/md"
import {
  planChanges,
  PlanChangeIdentifier,
  PlanChange,
} from "./PlanChangeRequest.data"
import capitalizeStr from "@modules/toolkit/helpers/capitalizeStr"

export type HeaderProps = {
  planChange?: PlanChange | null
}

export function Header({ planChange }: HeaderProps) {
  if (!planChange?.identifier) {
    return null
  }

  const text = planChanges[planChange.identifier]

  // next plan name is printed conditionally only for particular type change
  const nextPlanNameIsVisible =
    planChange.identifier ===
    PlanChangeIdentifier.DowngradeFromAgencyOrEnterprise

  // same for interval, it's printed only if plan tier stays unchanged
  const nextPlanIntervalIsVisible = planChange.action === `update`

  const billingIntervalFormatted =
    planChange.toInterval === BillingInterval.Annual ? `(Yearly)` : `(Monthly)`

  if (!text) {
    return null
  }

  return (
    <React.Fragment>
      <Heading>
        {text.heading}{" "}
        {nextPlanNameIsVisible && capitalizeStr({ str: planChange.to })}{" "}
        {nextPlanIntervalIsVisible && billingIntervalFormatted}
      </Heading>
      <p css={ledeCss}>{text.lede}</p>
      {text.note && (
        <p css={noteCss}>
          <MdInfo /> {text.note}
        </p>
      )}
    </React.Fragment>
  )
}

/* Header styles */

const ledeCss: ThemeCss = theme => ({})

const noteCss: ThemeCss = theme => ({
  background: theme.colors.yellow[5],
  border: `1px solid ${theme.colors.yellow[10]}`,
  color: theme.colors.yellow[90],
  margin: `${theme.space[8]} 0 ${theme.space[7]}`,
  padding: `${theme.space[5]} ${theme.space[7]}`,

  svg: {
    verticalAlign: `middle`,
    transform: `translate(0, -10%)`,
  },
})
