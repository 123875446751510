import * as React from "react"
import { FormikHelpers } from "formik"
import { SubscriptionPlan, User } from "@modules/graphql/types"
import { PlanInfo } from "../shared/constants/types"
import { ThemeCss } from "gatsby-interface"
import { Header } from "./PlanChangeRequest.parts"
import {
  identifyRequestedPlanChange,
  useHandleSubmitConfirmation,
} from "./PlanChangeRequest.helpers"
import { FormValues, PlanChangeRequestForm } from "./PlanChangeRequestForm"
import { PlanChangeRequestConfirmation } from "./PlanChangeRequestConfirmation"
import { useTriggerErrorAlert } from "@modules/ui/components/ErrorAlert"
import { PlanChangeIdentifier } from "./PlanChangeRequest.data"

export type PlanChangeRequestProps = {
  user: User
  organizationId: string
  nextPlan: PlanInfo
  currentPlan: SubscriptionPlan
  currentPlanOnTrial: boolean
}

export function PlanChangeRequest({
  user,
  organizationId,
  nextPlan,
  currentPlanOnTrial,
  currentPlan,
}: PlanChangeRequestProps) {
  const [setError, errorAlert] = useTriggerErrorAlert()
  const [formValues, setFormValues] = React.useState<FormValues>({
    firstName: user?.firstName ? user.firstName : ``,
    lastName: user?.lastName ? user.lastName : ``,
    email: user?.email ? user.email : ``,
    downgradeReason: ``,
    reasonDetail: ``,
  })
  const [confirmationModalIsOpen, setConfirmationModalIsOpen] = React.useState(
    false
  )
  const [setFormSubmitting, setSetFormSubmitting] = React.useState<
    (isSubmitting: boolean) => void | undefined
  >()

  const handleSubmitConfirmation = useHandleSubmitConfirmation({
    organizationId,
    currentPlan,
    setError,
  })

  const requestedPlanChange = identifyRequestedPlanChange({
    fromPlan: currentPlan.baseFeatures?.tier,
    toPlan: nextPlan.tier,
    isTrial: currentPlanOnTrial,
    fromInterval: currentPlan.interval,
    toInterval: nextPlan.billingInterval,
  })

  const isFormVisible =
    requestedPlanChange &&
    requestedPlanChange.identifier !==
      PlanChangeIdentifier.DowngradeToFreeOnTrial

  const handleSubmit = (
    values: FormValues,
    actions: FormikHelpers<FormValues>
  ) => {
    setFormValues(values)
    setConfirmationModalIsOpen(true)
    setSetFormSubmitting(() => actions.setSubmitting)
  }

  const handleConfirm = () => {
    setConfirmationModalIsOpen(false)
    handleSubmitConfirmation({ nextPlan, formValues, requestedPlanChange })
  }

  const handleConfirmationDismiss = () => {
    setConfirmationModalIsOpen(false)
    setFormSubmitting && setFormSubmitting(false)
  }

  return (
    <div css={rootCss}>
      <Header planChange={requestedPlanChange} />

      {isFormVisible && (
        <PlanChangeRequestForm
          formValues={formValues}
          onSubmit={handleSubmit}
          organizationId={organizationId}
          errorAlert={errorAlert}
          changeIdentifier={requestedPlanChange?.identifier}
        />
      )}

      <PlanChangeRequestConfirmation
        nextPlan={nextPlan}
        isOpen={confirmationModalIsOpen}
        onConfirm={handleConfirm}
        onDismiss={handleConfirmationDismiss}
      />
    </div>
  )
}

/* styles */

const rootCss: ThemeCss = theme => ({
  maxWidth: `50rem`,
  margin: `${theme.space[9]} auto`,
})
